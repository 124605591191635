<template>
  <div class="info-page">
    <InformationComponent/>
  </div>
</template>


<script>
import InformationComponent from "@/components/InformationComponent.vue";

export default {
  name: "InfoPage",
  components: {
    InformationComponent
  }
};
</script>

<style>
.info-page{
  background: #E1F2F9;
  min-height: calc(100vh - 100px);
}
</style>