













import { Component, Vue } from "vue-property-decorator";
import { NotesIlustration, TermsIlustration } from "./ilustrations";
@Component({
  components: {
    NotesIlustration, 
    TermsIlustration
  }
})
export default class MainPage extends Vue {}
